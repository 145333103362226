<template>
    <div class="overflow-auto" v-resize @resize="layoutTemplateResize"
         :style="{height: getTemplateHeight.px}"
    >
        <!-- title -->
        <div class="px-2 d-flex align-items-center justify-content-between" v-resize @resize="layoutHeaderResize" style="height: 30px">
            <div >{{$t('adventure.sider_experience.booked')}}</div>
            <div style="width: 100%" class="ml-2 d-flex justify-content-end">{{selectedRoute}}</div>
        </div>
        <!-- body -->
        <splitpanes class="default-theme" :style="{height:getBodyHeight.px}">
            <!-- pane: experiences -->
            <pane :min-size="getPaneSize.min" :max-size="getPaneSize.max" :size="getPaneSize.size">
                <!-- adventures -->
                <panel-adventure
                    :title="$t('adventure.sider_experience.booked') + ` (${$root.mx_countBooked})`"
                    :label1-text="null"
                    :fixed-width="false"
                    :width="paneMinWidth.px"
                    :max-height="getBodyHeight.value"
                    @refresh="loadRoutesFromBase()"
                    :draggable-off="true"
                >
                    <!-- body -->
                    <template #draggable-body-1>
                        <div class="d-flex flex-row flex-wrap">
                            <div v-for="(route, index) in routes" class="p-1">
<!--                                    :expand="getCardVariant(route).expand"-->
                                <folding-card
                                    :width="'300px'"
                                    :title="route.title + getCardVariant(route).text"
                                    :headerBgVariant="getCardVariant(route).variant"
                                    :header-text-variant="getCardVariant(route).textVariant"
                                    @foldingChange="eventFoldingChange($event, route)"
                                >
                                    <!-- buttons -->
                                    <template #headerButtons>
                                        <!-- icon when route is private -->
                                        <div v-if="route.private"
                                             v-b-tooltip.topright.hover.v-info.html="$t('adventure.route.private')"
                                        >
                                            <b-icon-person-fill
                                                variant="info"
                                                class="ml-1 py-0"
                                                scale="1"/>
                                        </div>
                                        <!-- button: show route -->
                                        <b-button
                                            class="ml-1 py-0"
                                            size="sm"
                                            @click.stop="showRoute(route)"
                                        >
                                            <b-icon-pin-map scale="0.7"/>
                                        </b-button>
                                    </template>
                                    <!-- body -->
                                    <template #body>
                                        <!-- info -->
                                        <folding-card
                                            :title="$t('form.info')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div class="bg-white d-flex flex-column py-1 px-2"
                                                     style="font-size: 10.5pt"
                                                >
                                                    <!-- route id -->
                                                    <div>
                                                        id: {{route.id}}
                                                    </div>
                                                    <!-- route stamp -->
                                                    <div>
                                                        {{route.stamp}}
                                                    </div>
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- departure -->
                                        <folding-card
                                            :title="$tc('adventure.departure', 1)"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                            :expand="true"
                                        >
                                            <template #body>
                                                <div
                                                    class="bg-white d-flex justify-content-between align-items-center py-1 px-2"
                                                    style="font-size: 10.5pt"
                                                >
                                                    <!-- departure date -->
                                                    <div>
                                                        <b-icon-calendar-date/>
                                                        {{__convertBaseDateToLocaleDateString(route.booked.departureDate)}}
                                                    </div>
                                                    <!-- departure time -->
                                                    <div>
                                                        <b-icon-clock/>
                                                        {{__trimmedShapeTime(route.booked.departureTime)}}
                                                    </div>
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- arrival -->
                                        <folding-card
                                            v-if="!__isNull(route.booked.arrivalDate) || !__isNull(route.booked.arrivalTime)"
                                            :title="$tc('adventure.arrival', 1)"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div
                                                    class="bg-white d-flex justify-content-between align-items-center py-1 px-2"
                                                    style="font-size: 10.5pt"
                                                >
                                                    <!-- arrival date -->
                                                    <div>
                                                        <b-icon-calendar-date/>
                                                        {{__convertBaseDateToLocaleDateString(route.booked.arrivalDate)}}
                                                    </div>
                                                    <!-- arrival time -->
                                                    <div v-if="!__isNull(route.booked.arrivalTime)">
                                                        <b-icon-clock/>
                                                        {{__trimmedShapeTime(route.booked.arrivalTime)}}
                                                    </div>
                                                </div>
                                            </template>
                                        </folding-card>
                                        <!-- guests -->
                                        <folding-card
                                            :title="$t('adventure.experience.guests')"
                                            class="mt-1"
                                            header-bg-variant="white"
                                            :header-text-variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                            :is-inside="true"
                                        >
                                            <template #body>
                                                <div v-for="guest in getGuests(route.booked.guests)"
                                                     class="d-inline-block px-1" style="font-size: 10.5pt;">
                                                    {{guest}}&nbsp;
                                                </div>
                                            </template>
                                        </folding-card>
                                    </template>
                                    <!-- footer -->
                                    <template #footer>
                                        <div
                                            class="bg-white d-flex flex-row align-items-center d-flex justify-content-center my-1"
                                            style="width: 100%; border-radius: 0 0 5px 5px;">
                                            <!-- button let's go -->
                                            <div class="flex-grow-1 d-flex justify-content-center my-1">
                                                <b-button
                                                    :variant="index % 2 === 0 ? 'secondary' : 'primary'"
                                                    @click="__gotoDestinations(route.stamp)"
                                                >
                                                    {{$t('adventure.route.lets_go')}}
                                                    <b-icon-emoji-sunglasses scale="0.8"/>
                                                </b-button>
                                            </div>
                                        </div>
                                    </template>
                                </folding-card>
                            </div>
                        </div>
                    </template>
                </panel-adventure>
            </pane>
            <!-- pane: leaflet map -->
            <pane>
                <!-- <leaflet-map-->
                <panel-map style="width:100%">
                    <template #map>
                        <leaflet-map2
                            :routing="routing"
                            :hotlink="hotlink"
                        >
                            <!-- map body -->
                            <template #popupBody>
                                <popup-destination2
                                    :data="popupData"
                                    :hotlink.sync="hotlink"
                                />
                            </template>
                        </leaflet-map2>
                    </template>
                </panel-map>
            </pane>
        </splitpanes>
    </div>
</template>

<script>
import PanelAdventure from "@/components/_public/_adventures2/elements/PanelAdventure";
import PanelMap from "@/components/_public/_adventures2/elements/PanelMap";
import FoldingCard from "@/components/general/elements/layoutsElements/FoldingCard";
import LeafletMap2 from "@/components/_public/_adventures2/elements/LeafletMap2";
import PopupDestination2 from "@/components/_public/_adventures2/elements/PopupDestination2";

export default {
    name: "Booked",
    components: {PopupDestination2, LeafletMap2, FoldingCard, PanelMap, PanelAdventure},
    props: {},
    data() {
        return {
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            templateLayout: {},
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            layout: [],
            // <-- pane experience -->
            paneMinWidth: {
                value: 200,
                px: 200 + 'px',
            },
            // <-- pane map -->
            paneMapMinWidth: {
                value: 480,  // <-- should bi modal window width -->
                px: 480 + 'px'
            },
            // <-- base -->
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                main: `api/v1/adventure2`,
                getDestination: `api/v1/_public/get_destination`,
            },
            routes: [],
            routing: [],
            selectedRoute: null,
            // <-- leafletMap -->
            hotlink: {
                source: null,
                destinationId: null,
                experienceId: null,
                selected: false,
                popupOpen: false,
            },
            popupData: {},
        }
    },
    mounted() {
        this.loadRoutesFromBase();
    },
    methods: {
        // @ignore <-- layout -->
        layoutTemplateResize(e) {
            this.templateLayout = {
                width: e.detail.width,
                height: e.detail.height,
            };
        },
        /**
         * @vuese
         * Added layout data (width, height) for calculating page or component optimal size (height).
         * @arg event
         */
        layoutHeaderResize(e) {
            let layout = this.__layoutAdd(this.layout, 'title', e.detail.width, e.detail.height);
        },
        // <-- events -->
        eventFoldingChange(e, booked) {
            if (!e.folded) {
                this.showRoute(booked);
            }
        },
        // <-- actions -->
        showRoute(booked) {
            // this.$root.mx_busy = true;
            // <-- route -->
            let route = {
                id: booked.id,
                stamp: booked.stamp,
                title: booked.title,
                lineOptions: null
            }
            // <-- destinations -->
            let destinations = [];
            let destination;
            booked.experiences.forEach(experience => {
                destination = experience.destination;
                // <--  -->
                destinations.push({
                    destinationId: destination.id,
                    experienceId: experience.id,
                    title: destination.name,
                    subtitle: destination.subtitle,
                    latLng: {
                        lat: destination.coordinates.latitude,
                        lng: destination.coordinates.longitude
                    }
                });
            });
            // <-- routing  -->
            let routing = [];
            routing.push(
                {
                    route: route,
                    destinations: destinations
                }
            );
            this.selectedRoute = routing[0].route.title;
            this.$set(this, 'routing', routing);
        },
        // <-- base -->
        loadRoutesFromBase() {
            this.$root.mx_busy = true;
            this.routes = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            // this.axios.get(this.uri.main, config)
            this.axios.get(this.uri.main + '/get_booked', config)
                .then((response) => {
                    this.$set(this, 'routes', response.data);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
        },
        loadDestinationFromBase(destinationId) {
            this.$root.mx_busy = true;
            this.popupData = {};
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getDestination + `/${destinationId}`, config)
                .then((response) => {
                    this.$set(this, 'popupData', response.data.record);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    console.log("ERROR:", error.response.data);
                });

        },
        // <-- data -->
        getGuests(guests) {
            let array = [];
            let guest = '';
            for (let key in guests) {
                if (guests[key] > 0) {
                    guest = this.$tc(`adventure.experience.${key}`, 1) + ': ';
                    guest += guests[key];
                    guest += ',';
                    array.push(guest);
                }
            }
            // <-- remove last comma -->
            array[array.length - 1] = array[array.length - 1].substring(array[array.length - 1], array[array.length - 1].length - 1)
            return array;
        },
        getCardVariant(route) {
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            let today = new Date();
            let date = new Date(route.booked.departureDate);
            let dateIsToday = date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDay() === today.getDay();
            // <--  -->
            // let text = date.toLocaleDateString(this.__getVueLocale());
            let text = Math.round((date - today) / oneDay);
            // <--  -->
            let variant = date > today ? 'secondary' : 'primary';
            let textVariant = date > today ? 'primary' : 'secondary';
            // <-- today -->
            if (dateIsToday) {
                variant = 'secondary';
                textVariant = 'white';
                text = this.$t('form.datepicker.today').toLowerCase();
            }
            // <-- tomorrow -->
            else if (parseInt(text) === 1) {
                variant = 'secondary';
                textVariant = 'white';
                text = this.$t('date_time.tomorrow').toLowerCase();
            }
            // <-- expired -->
            else if (text < 0) {
                variant = 'primary';
                textVariant = 'light';
                text = null;
            } else {
                text += ' ' + this.$tc('date_time.day', 0).toLowerCase();
            }
            // <--  -->
            return {
                variant: variant,
                textVariant: textVariant,
                expand: dateIsToday,
                text: text === null ? '' : ' (' + text + ')'
            }
        }
    },
    computed: {
        getTemplateHeight() {
            let height = this.__layoutCenterHeightCalculate.value - this.$adventuresSubHeight;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        getBodyHeight() {
            let height = this.getTemplateHeight.value - this.__layoutHeightCalculate(this.layout);
            height -= 0;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        // <-- pane -->
        getPaneSize() {
            // <-- minSize -->
            let minSize = (this.paneMinWidth.value + 60) * 100 / this.templateLayout.width;
            minSize = minSize.toFixed(0);
            // <-- panelMapMinSize -> maxSize  -->
            let maxSize = (this.templateLayout.width - this.paneMapMinWidth.value) * 100 / this.templateLayout.width;
            maxSize = maxSize.toFixed(2) - 0.5;
            // <-- when monitor is smaller then minSize + maxSize -->
            if (minSize > maxSize) {
                minSize = 50;
                maxSize = 50;
            }
            // <--  -->
            return {
                min: minSize,
                max: 80, // maxSize, // TODO change pane value!!!
                size: 50,
            }
        },
    },
    filters: {},
    watch: {
        'hotlink': {
            handler: function (value, oldValue) {
                if (value.source !== this.$options.name) {
                    if (value.popupOpen && this.popupData.id !== value.destinationId) {
                        this.loadDestinationFromBase(value.destinationId);
                    }
                    //     this.setExperienceSelect();
                    //     // <-- scrollTo -->
                    //     this.scrollToExperience();
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>

